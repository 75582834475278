import React from 'react';
import { Link } from 'gatsby'
const CalcBanner = () => {



  return (
    <div>
      <Link
        to="/calc-modal/"
        state={{
          modal: true
        }}
      >
        Redundancy Calculator
</Link>
    </div>
  )

}

export default CalcBanner;