import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import rehypeReact from "rehype-react"
//import Header from '../components/header'
import HomeHeaderV2 from '../components/home-header-v2'
import Footer from '../components/footer'
import InsightsBar from '../components/insights-bar'
import LetterBoxCall from '../components/letterbox-call'
import SavingBlock from '../components/saving-block'
import FiftyFifty from '../components/fiftyfifty'
//import CovidInfo from '../components/covid-info'
import FourServices from '../components/four-services';
import MdPhone from '../components/md-phone';
import DebtFour from '../components/debt-four';
import BannerAd from '../components/BannerAd';
import FurtherReading from '../components/further-reading';
import '../components/style.scss';
import CalcBanner from '../components/calc-banner'



export const query = graphql`
    query($slug:String!, $clientStories:[String], $insolvencyInsights:[String]) {
        mainContent: markdownRemark(fields: {slug: { eq: $slug } }) {
            frontmatter {
                title
            }
            htmlAst
            wordCount{
                words
            }
        }
        insights: allMarkdownRemark(filter:{
            frontmatter: {
              shortTitle: {
                in: $insolvencyInsights
              }
              
            }
            
          }){
             edges {
              node {
                frontmatter {
                  title
                  shortTitle
                  image {
                    childImageSharp {
                        fluid(maxWidth: 404, maxHeight: 280, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                
                }
                html
                excerpt
                fields {
                    slug
                }
              }
            }
          }
        sidebar: allMarkdownRemark(filter:{
            frontmatter: {
              shortTitle: {
                in: $clientStories
              }
              
            }
            
          }){
             edges {
              node {
                snippet
                frontmatter {
                  title
                  shortTitle
                  image {
                    childImageSharp {
                        fluid(maxWidth: 404, maxHeight: 280, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                
                }
                html
                excerpt
                fields {
                    slug
                }
              }
            }
          }
        
    }
`
const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        "md-phone": MdPhone,
        "debt-four": DebtFour,
        "further-reading": FurtherReading,
        "calc-banner": CalcBanner,
        "banner-ad": BannerAd
    },
}).Compiler

const Service = (props) => {
    const { pageContext } = props

    // Sort the outputed stories to match the array

    console.log("The Original Array order: " + pageContext.clientStories);

    // Loop through the pageContext clientStories array from the frontmatter and reorder the outputted query array/object to match
    var sortedStories = []
    for (var clientTitle of pageContext.clientStories) {
        props.data.sidebar.edges.map((edge) => {
            if (edge.node.frontmatter.shortTitle === clientTitle) {
                sortedStories.push(edge)
            }
            return null;
        })
    }

    // Do the same again for the Insolvency Insights
    var sortedInsights = []
    for (var insightTitle of pageContext.insolvencyInsights) {
        props.data.insights.edges.map((edge) => {
            if (edge.node.frontmatter.shortTitle === insightTitle) {
                sortedInsights.push(edge)
            }
            return null;
        })
    }

    // Trim the array to insert the correct amount of stories in the sidebar
    var words = props.data.mainContent.wordCount.words;
    var finalStories = [];
    // Trim the insights block to 3 stories /////////////////////////
    var finalInsights = sortedInsights.slice(0, 3);
    ////////////////////////////////////////////////////////////////
    // if there are more than 900 words have all 6 stories
    if (words >= 1000) {
        finalStories = sortedStories
    } else if (words >= 800 && words < 1000) {
        finalStories = sortedStories.slice(0, 5)
    } else if (words >= 600 && words < 800) {
        finalStories = sortedStories.slice(0, 4)
    } else {
        finalStories = sortedStories.slice(0, 3)
    }

    return (
        <div>
            <HomeHeaderV2 pageTitle={props.data.mainContent.frontmatter.title} />
            <FourServices />
            <section className="section service-section">
                <div className="container content">
                    <div className="columns">
                        <div className="column is-three-quarters">
                            <h1>{props.data.mainContent.frontmatter.title}</h1>
                            {/* Main Content */}
                            {/* <div className="content story-main-content" dangerouslySetInnerHTML={{ __html: props.data.mainContent.html }}></div> */}
                            <div className="content story-main-content">{renderAst(props.data.mainContent.htmlAst)}</div>

                            <InsightsBar data={finalInsights} />

                        </div>
                        <div className="column is-one-quarter">
                            <h2>Real Client Stories</h2>
                            {finalStories.map((edge) => {
                                let linkSlug = "/" + edge.node.fields.slug;
                                return (
                                    <div className="container content sidebar-story">
                                        <article>
                                            <header>
                                                <div className="image-thumbnail">
                                                    <Link to={linkSlug}>
                                                        <Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
                                                    </Link>
                                                </div>
                                                <p className="post-meta sidebar-story-meta">
                                                    <Link className="title is-size-5" to={linkSlug}>{edge.node.frontmatter.title}</Link>
                                                </p>
                                            </header>
                                            <p className="sidebar-story-excerpt">
                                                <div className="snippet" dangerouslySetInnerHTML={{ __html: edge.node.snippet }} />


                                            </p>
                                        </article>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <LetterBoxCall />
            <SavingBlock />
            <FiftyFifty />
            <Footer />
        </div>
    )
}

export default Service